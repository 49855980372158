import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';

const envMode = process.env.ENV_MODE; //dev || stage || production

export const isFeatureEnabled = (cookies, config, feature) => {
  const urlFeatureFlags = cookies['enabledFeatureFlags'] || '';
  const disabledFeatureFlags = cookies['disabledFeatureFlags'] || '';
  if (disabledFeatureFlags?.includes(feature)) return false;
  return config.featuresList?.[envMode]?.includes(feature) || urlFeatureFlags?.includes(feature);
};
/**
 * SwitchableFeature
 * @param {[[string: feature, bool: isEnabled]]} dependencies - list of feature dependencies
 * @param {string} feature - a single feature dependency

 * @returns children if depencies are resolved, fallback or null otherwise
 */
export const SwitchableFeature = ({ dependencies, feature, children, fallbackFn }) => {
  const config = useSelector(({ config }) => config);
  const [cookies] = useCookies(['enabledFeatureFlags']);
  const singleFeatureDependency = typeof feature === 'string';
  const multiFeatureDependency = Array.isArray(dependencies);
  const isEnabled = useCallback((feature) => isFeatureEnabled(cookies, config, feature), [cookies, config]);
  //URL enabled feature flags are meant to be for testing purposes only
  if (singleFeatureDependency && isEnabled(feature)) return children;
  if (multiFeatureDependency) {
    const failedDependency = dependencies.find(([feature, enabled]) =>
      enabled ? !isEnabled(feature) : isEnabled(feature)
    );
    if (failedDependency === undefined) return children;
  }
  if (fallbackFn) return fallbackFn();
  return null;
};

export const switchableRoute = (cookies, config, feature) => {
  return !!isFeatureEnabled(cookies, config, feature);
};

export const useSwitchableFeatures = (features) => {
  const config = useSelector(({ config }) => config);
  const [cookies] = useCookies(['enabledFeatureFlags']);
  const isEnabled = useCallback((feature) => isFeatureEnabled(cookies, config, feature), [cookies, config]);

  return features?.map((feature) => isEnabled(feature));
};
